<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Client Company</label>
        <v-select
          placeholder="Select companies"
          ref="clientCompanies"
          :class="[{'vs-error': v$.clientCompany.$error}]"
          :options="clientCompanies"
          label="name"
          v-model="clientCompany"
        />
      </div>
    </div>

    <div v-if="v$.clientCompany.$error" class="col-sm-12" style="margin-top: -7px;">
      <div class="error">Client info is required</div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },

  props: {
    parameters: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      clientCompanies: this.clientCompanies,
      clientCompany: null,
    }
  },

  watch: {
    clientCompany: function() {
      this.updateParameters('client_company_id', this.clientCompany ? this.clientCompany.id : '');
    },
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  validations () {
    return {
      clientCompany: {
        required
      }
    }
  },

  mounted: function() {
    this.fetchClientCompanies();
  },

  methods: {
    updateParameters: function(param, value) {
      this.$emit('update:parameters', { ...this.parameters, [param]: value });
    },

    fetchClientCompanies: function() {
      let fetchParams = {
        orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },
  }
}
</script>
